import { useEffect } from "react";
import "./blog.css";
import { NavLink } from "react-router-dom";
import blogData from "./Blogdata";

console.log(blogData);
const Blog = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="maincontainer">
        <h1 className="blogpage1 text-center"> MIRACLE IT SKILLS BLOG PAGE </h1>
      </div>
      <div className="row mt-5" style={{ width: "98%", margin: "auto" }}>
        <div className="col-sm-6 mx-auto">
          <h6 className="marginauto">
            {" "}
            At Miracle IT Skills, we are not only passionate about providing
            top-notch IT education but also about sharing our knowledge and
            insights with the broader tech community. Our blog is your go-to
            resource for staying informed, inspired, and ahead of the curve in
            the dynamic world of information technology.
          </h6>

          <div className="our countainer0">
            <div>
              {blogData.map((blog) => (
                <div key={blog.id}>
                  <div className="blog-card alt">
                    <div className="meta">
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${blog.image})`,
                        }}
                      />
                      <ul className="details">
                        <li className="author">
                          <NavLink to="/">{blog.author}</NavLink>
                        </li>
                        <li className="date">{blog.date}</li>
                        <li className="tags">
                          <ul>
                            {blog.tags.map((tag, index) => (
                              <li key={index}>
                                <NavLink to="/">{tag}</NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="description">
                      <h1>{blog.title}</h1>
                      <p>{blog.content}</p>
                      <p className="read-more">
                        <NavLink to={`/blog/${blog.id}`} key={blog.id}>
                          Read More
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
