import { useEffect } from "react";
import "./cyber.css";
// import { NavLink } from "react-router-dom";

const Kartavya = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid main">
        <div className="headerka">
          <img src={require("../../assest/Miracle - IT (4).jpg")} className="imageheaderka" alt="miracleitskills" />
          <img src={require("../../assest/Date1007.jpg")} className="imageheaderka1" alt="miracleitskills" />
        </div>
      </div>
      <div className="card-group">
        <div
          className="card"
          style={{ backgroundColor: "#275f9a", alignItems: "center" }}
        >
          <img
            src={require("../../assest/USP1.webp")}
            className="miracleimg"
            alt="Miracle IT Career"
          />
          <div className="cardbody">
            <h5 className="servicecard">Certified Trainers</h5>
            <p className="cardtext">
              Our well qualified trainers are equiped with the skills and
              knowledge to give you the best training.
            </p>
          </div>
        </div>
        <div
          className="card"
          style={{ backgroundColor: "#0d1128", alignItems: "center" }}
        >
          {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
          <img
            src={require("../../assest/USP2.webp")}
            className="miracleimg"
            alt="Miracle IT Career"
          />
          <div className="cardbody">
            <h5 className="servicecard">Certification</h5>
            <p className="cardtext">
              Ties with IT/ITes Sector Skill Council NASSCOM for the technical
              assessments and certifications.
            </p>
          </div>
        </div>
        <div
          className="card"
          style={{ backgroundColor: "#275f9a", alignItems: "center" }}
        >
          <img
            src={require("../../assest/USP3.webp")}
            className="miracleimg"
            alt="Miracle IT Career"
          />
          <div className="cardbody">
            <h5 className="servicecard">Training Facility</h5>
            <p className="cardtext">
              State-of-art infrastructure and aminities with multiple city
              presence helps us to serve our students better.
            </p>
          </div>
        </div>

        <div
          className="card"
          style={{ backgroundColor: "#0d1128", alignItems: "center" }}
        >
          <img
            src={require("../../assest/USP4.webp")}
            className="miracleimg"
            alt="Miracle IT Career"
          />
          <div className="cardbody">
            <h5 className="servicecard">Placements</h5>
            <p className="cardtext">
              Training & Placement assistance and post-placement support for a
              healthy and long term relationship.
            </p>
          </div>
        </div>
      </div>
      <div
        className="row itskills"
        style={{
          marginTop: "12px",
          marginLeft: "10px",
          marginRight: "20px",
          padding: "2%",
        }}
      >
        <div
          className="col-sm-3 mb-3 mb-sm-0 colsm66 "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assest/Miracle - IT (1).webp")}
            style={{ width: "84%", height: "50rem" }}
            className="mycolm666"
            alt="about1"
          />
        </div>
        <div className="col-sm-9 colsm66">
          <h2 className="hh1"> Our Methodology</h2>
          {/* <span className="animate-border border-black"></span> */}

          <p className="pp1">
            <h5 className="pp1my">We Engage our Learners.</h5>
            We make a learner understand how to set career direction, and shape
            your work-life for maximum success. Moving on to finding out how to
            get ahead, and then discover how to deal with the many challenges
            that could otherwise set them back.{" "}
            <h5 className="pp1my">
              We start by Cross verification of Interest of learner.
            </h5>
            We start by Cross verification of Interest of learner for Training &
            Placement in respective job-role and relocation for placement, if
            required. This eventually reduces the chance of dropout and increase
            the retention of candidate's interest in training. Trainings are
            conducted for setting career direction of learner with the help of
            various interest-boosting resources, which are researched, reviewed,
            and designed to help a learner in taking immediate action to develop
            their skills. We deliver leadership, team and personal development
            training to learners in order to make them employable. With
            interactive content and training session we help learners to enjoy
            successful careers, and contribute positively to the success of
            their organizations.{" "}
            <h5 className="pp1my">Training of Trainers </h5> As we know, the
            trainer is considered the professional agent and the most directly
            responsible person in the process of learning. Hence, we give
            importance to regular Training of Trainers.{" "}
            <h5 className="pp1my">We train the trainers </h5>We train the
            trainers in order to shift from traditional-based or theoretical
            training to a research-theory-based and activity-based training that
            informs and inspires learners. We help our trainers in incorporating
            new research findings in their training practices to improve
            learning and assure high-quality training.{" "}
            <h5 className="pp1my">We Observe and Evaluate </h5>
            We Observe and Evaluate trainer's training delivery and performance
            on a regular basis. Complete analysis and evaluation is conducted on
            the basis of Live monitoring, Video analysis, various feedbacks from
            learners, feedback from respective industry people, performance of
            trainees to name a few.
          </p>
        </div>
      </div>

      <div className="whymp">
        <div class="row">
          <div class="col-sm-9">
            <h2 className="hh1">Project Details</h2>

            <p className="whymp3">
              <ul>
                <li style={{ textAlign: "justify" }}>
                  <strong>
                    Bridging the gap between industry requirement and aspirant’s
                    qualifications:-
                    <br />
                  </strong>{" "}
                  Miracle Group is having a huge network of 150+ IT companies as
                  placement partner. The Course Curriculum is prepared in
                  consultation with industry professionals keeping in mind the
                  syllabus of job roles prescribed under NSQF and QPs of the
                  IT/ITes Sector Skill Council.
                </li>
                <li>
                  <strong> Targeted Job Roles</strong>

                  <ul>
                    <li style={{ listStyle: "none" }}>
                      ⦁ IT Infrastructure Engineer- SSC/Q0801- for syllabus
                      refer
                    </li>
                    <li style={{ listStyle: "none" }}>
                      {" "}
                      ⦁ Web developer - SSC/Q0503- for syllabus refer{" "}
                    </li>
                    <li style={{ listStyle: "none" }}>
                      {" "}
                      ⦁ Junior Software developer- SSC/Q0508- for syllabus refer{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong> Certification and Third Party Assessment-</strong>
                  <br />
                  Miracle Group has signed a TPA MoU IT/ITes Sector skill
                  Council NASSCOM refer
                </li>
              </ul>
            </p>
          </div>
          <div class="col-sm-3">
            <img
              src={require("../../assest/USP (1).webp")}
              style={{ width: "100%" }}
              className="fristimg"
              alt="Miracle IT Career"
            />
          </div>
        </div>

        <div class="row placementrow">
          <div class="col-sm-3" style={{ margin: "auto" }}>
            <img
              src={require("../../assest/placement (1).webp")}
              style={{ width: "100%" }}
              className="fristimg"
              alt="Miracle IT Career"
            />
          </div>
          <div class="col-sm-9 mt-2">
            <h2 className="hh1">Placement And Tracking </h2>
            <p className="whymp3" style={{ textAlign: "justify" }}>
              Prior to actual training, We assess the industry requirement and
              existing skill gap to adopt most suitable course curriculum
              content methodology. While the candidate is getting trained, we
              continuously monitor the level of involvement and outcome using
              latest IT based tools and techniques. After successful completion
              of the training, each trainee is assessed and certified by a third
              party. We then leverage its industry network to ensure appropriate
              placement for certified trainees. During initial period of their
              employment, we track our trainees through regular interaction and
              handhold them to ease the induction process.
            </p>
            <p className="whymp3">
              <ul>
                <li>
                  <strong>
                    Placement arrangement will be done by our organization.
                  </strong>
                </li>
                <li>
                  <strong>
                    Post Placement support and tracking plan for trainees
                    trained from this project.
                  </strong>
                </li>
                <li>
                  <strong>Post placement assistance </strong>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kartavya;
