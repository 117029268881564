import React from "react";
import "./placement.css";

const PlacementSupport = () => {
  return (
    <>
      <div className="header">
        <h1>
          {" "}
          Unlock Your Career Potential with<br/> Miracle IT Skills' Placement Support
        </h1>
        <p className="outreacgfrinches">
          {" "}
          At Miracle IT Skills, we understand that education is not just about
          learning; it's about transforming your future. That's why we offer
          comprehensive placement support services to our students, ensuring
          they step confidently into the world of IT careers.
        </p>
      </div>
      <div
        className="row placementsupport"
        style={{
          padding: "25px",
          marginTop: "2%",
          width: "80%",
          margin: "auto",
        }}
      >
        <div className="row text-center">
          <h1 className="placementpageh1 text-centre">
            {" "}
            Your path to IT success begins here
          </h1>
          <p className="placementpagep">
            {" "}
            At Miracle IT Skills, we empower you with the skills, knowledge, and
            connections you need to thrive in the competitive IT job market.
            With our placement support, you're not just getting an education;
            you're investing in a future filled with promising opportunities.
            <br />
            <br />
            Join us, and let's take the next step toward your dream IT career
            together. Your success is our mission.{" "}
          </p>
        </div>
        <div className="col-sm-3 mb-3 my-3">
          <div className="card placementcard h-100" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title cardtitel1">Student Care</h5>
              <p className="card-text">
                Student helpline and placement assistance opportunities for the
                students.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 my-3 my-3">
          <div className="card placementcard h-100" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title cardtitel1">Career solutions</h5>
              <p className="card-text">
                Future career opportunities will be discussed and shared with
                our students.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 my-3">
          <div className="card placementcard h-100" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title cardtitel1"> Expert Guidance</h5>
              <p className="card-text">
                Confused with the career options? We will provide the right
                counselling to you.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 my-3">
          <div className="card placementcard h-100" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title cardtitel1">Recruiters</h5>
              <p className="card-text">
                HCL Limited, Unisys Solutions, Wangoes Technology, Cognizant,
                EY, Netlink and more.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pclacementcontainer" style={{ padding: "5%" }}>
        <div className="row">
          <div className="col-sm-3 mb-3 mb-sm-0">
            <div className=" placementcard11">
              <div className="card-body">
                <img
                  src={require("../../assest/project (1).webp")}
                  className="rocket"
                  alt="Miracle IT Career"
                />
                <h5 className="card-title placmenttitel">10,000+ </h5>
                <p className="card-text placementtext">Job Opportunities</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className=" placementcard11">
              <div className="card-body">
                <img
                  src={require("../../assest/users1.webp")}
                  className="rocket"
                  style={{ height: "10vh" }}
                  alt="Miracle IT Career"
                />

                <h5
                  className="card-title placmenttitel"
                  style={{ marginBottom: "12px" }}
                >
                  150+
                </h5>
                <p className="card-text placementtext">IT Recruiters</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="placementcard11">
              <div className="card-body">
                <img
                  src={require("../../assest/rocket1.webp")}
                  className="rocket white-image"
                  style={{ height: "11vh" }}
                  alt="Miracle IT Career"
                />
                <h5 className="card-title placmenttitel">12+</h5>
                <p className="card-text placementtext">Years of Experience</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="  placementcard11">
              <div className="card-body ">
                <img
                  src={require("../../assest/trophy.webp")}
                  className="rocket"
                  style={{ height: "10vh" }}
                  alt="Miracle IT Career"
                />
                <h5 className="card-title placmenttitel">Numerous</h5>
                <p className="card-text placementtext">Placed Students</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row maycardp1">
        <div className="col-sm-4 my-3">
          <div className="card maycardp1plac h-100">
            <div className="image11-container">
              <img
                src={require("../../assest/Build your resume1.jpg")}
                className="pcardimg"
                alt="Miracle IT Career"
              />
            </div>
            <div className="card-body" style={{ textAlign: "center" }}>
              <h5 className="wd-image-text">Build Your Resume</h5>
              <p className="card-text placementp11">
                We help you create a standout resume that showcases your skills,
                knowledge, and achievements, making you a top candidate for
                employers.
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-4 my-3">
          <div className="card maycardp1plac h-100">
            <div className="image11-container">
              <img
                src={require("../../assest/interviewplacement.jpg")}
                className="pcardimg"
                alt="Miracle IT Career"
              />
            </div>
            <div className="card-body" style={{ textAlign: "center" }}>
              <h5 className="wd-image-text">Interview Preparation</h5>
              <p className="card-text placementp11">
                Our interview coaching equips you with the confidence and skills
                needed to excel in job interviews. We conduct mock interviews to
                fine-tune your responses and presentation.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 my-3">
          <div className="card maycardp1plac h-100">
            <div className="image11-container">
              <img
                src={require("../../assest/Ongoing support1.jpg")}
                className="pcardimg"
                alt="Miracle IT Career"
              />
            </div>
            <div className="card-body" style={{ textAlign: "center" }}>
              <h5 className=" wd-image-text">Ongoing Support</h5>
              <p className="card-text placementp11">
                Our commitment to your success doesn't end with graduation. We
                continue to support you throughout your career journey,
                providing job placement assistance and career advice
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacementSupport;
