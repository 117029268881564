import React, { createContext, useContext } from "react";

const BlogContext = createContext();

export const useBlogData = () => {
  return useContext(BlogContext);
};

const BlogProvider = ({ children }) => {
  // Define your blog data here
  const blogData = [
    {
        id: 1,
        image: require("../src/assest/blog1.png"),
        author: "Mr. Amit Dubey",
        date: "July 15, 2015",
        tags: ["Learn", "Code", "JavaScript"],
        title:
          "Why Capturing Email Is So Important for Your Website & How to Do It",
        content:
          "Growing your email list is essential for online marketing and has a multi-faceted effect on your marketing results. It helps you engage potential customers, build relationships, grow revenue, and expand business opportunities. Email might seem like an outdated marketing channel, but it’s still one of the most effective techniques you...",
        readMoreLink: "/singleblog",
      },
      {
        id: 2,
        image: require("../src/assest/blog2.png"),
        author: "Mr. Amit Dubey",
        date: "July 15, 2015",
        tags: ["Learn", "Code", "JavaScript"],
        title:
          "How Many Websites Use HTML? 10 Astonishing Statistics That Shape Web Development",
        content:
          "HTML is the most prevalent programming language in the modern world. Even the least tech-savvy laymen out there have heard of it and come in contact with it daily when they go online. However, while we all know that the entire internet basically runs on HTML, it’s difficult to understand just how mind-bogglingly dominant it...",
        readMoreLink: "/",
      },
      {
        id: 3,
        image: require("../src/assest/blo3.png"),
        author: "Mr. Amit Dubey",
        date: "July 15, 2015",
        tags: ["Learn", "Code", "JavaScript"],
        title: "Crucial Cloudflare Statistics You Should Know in 2023",
        content:
          "Cloudflare is one of the most prominent internet security companies. Since its 2009 establishment, it has grown continuously in size and number of users. Cloudflare offers cloud security, domain registration, DDoS mitigation, and a content delivery network. It’s a big part of the internet. Chances are you’ve used or are using sites powered by Cloudflare.",
        readMoreLink: "/",
      },
  ];

  return (
    <BlogContext.Provider value={blogData}>{children}</BlogContext.Provider>
  );
};

export default BlogProvider;
