import { useState } from "react";
import { NavLink } from "react-router-dom";
import DehazeIcon from "@mui/icons-material/Dehaze";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import "./navbar.css";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // Function to close the navbar
  const closeNavbar = () => {
    setShowNavbar(false);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          {" "}
          <img
            src={require("../../assest/miracleitlogowebp.webp")}
            className="logomain"
            alt="Miracle IT Groups"
          />
          Miracle IT Skills{" "}
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <DehazeIcon className="fristicon" />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/" onClick={closeNavbar}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={closeNavbar}>
                About
              </NavLink>
            </li>

            <div className="dropdown">
              <button className="dropbtn">Govt.Project</button>
              <div className="dropdown-content">
                <NavLink
                  to="/kartavya"
                  className="dropsubtitel"
                  onClick={closeNavbar}
                >
                  Kartavya
                </NavLink>
                <NavLink
                  to="/cyber"
                  className="dropsubtitel"
                  onClick={closeNavbar}
                >
                  Cyber Satarkta
                </NavLink>
                <NavLink
                  to="/adsd"
                  className="dropsubtitel"
                  onClick={closeNavbar}
                >
                  ADSD
                </NavLink>
              </div>
            </div>
            <li>
              <NavLink to="/outreach" onClick={closeNavbar}>
                Franchise
              </NavLink>
            </li>
            {/* <div className="dropdown">
              <button className="dropbtn">School</button>
              <div className="dropdown-content">
                <div className="dropsubtitel13">
                  <button className="dropbtn btnnn12">
                    Tec-Champ <ChevronRightIcon />
                  </button>
                  <div class="dropdown-content11">
                    <NavLink
                      to="/cource"
                      class="dropsubtitel123"
                      onClick={closeNavbar}
                     >
                     Junior Program
                    </NavLink>
                    <NavLink
                      to="/cource1"
                      class="dropsubtitel123"
                      onClick={closeNavbar}
                    >
                    Senior Program 
                    </NavLink>
                  </div>
                </div>

                <NavLink
                  to="/adsd"
                  className="dropsubtitel"
                  onClick={closeNavbar}
                >
                  Diploma
                </NavLink>
              </div>
            </div> */}
            <li>
              <NavLink to="/school" onClick={closeNavbar}>
                School
              </NavLink>
            </li>

            <li>
              <NavLink to="/placement" onClick={closeNavbar}>
                Placement
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" onClick={closeNavbar}>
                Blog
              </NavLink>
            </li>
            <li>
              {/* <button type="button" class="btn btn-outline-success loginbutton">
                {" "}
                <NavLink to="/login" onClick={closeNavbar}>Login</NavLink>/
                <NavLink to="/ragister" onClick={closeNavbar}>Register</NavLink>
              </button> */}
              <NavLink to="/contact" onClick={closeNavbar}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
