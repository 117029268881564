import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Inquri from "../../Inquri";
import "./outreach.css";
const Franchise = () => {
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);

  const toggleEnquiryForm = () => {
    setShowEnquiryForm(!showEnquiryForm);
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Inquri /> */}
      <div className="headerfrinches">
        <h1>Become Financhise Partner</h1>
        <p className="outreacgfrinches">
          {" "}
          Are you passionate about technology education and eager to make a
          difference in your community? Join us at Miracle IT Skills as an
          Franchise Center and be a catalyst for change in the world of IT
          training.
          <br /> क्या आप प्रौद्योगिकी शिक्षा के बारे में उत्साही हैं और अपने
          समुदाय में बदलाव लाने के लिए उत्सुक हैं? एक आउटरीच सेंटर के रूप में
          मिरेकल आईटी स्किल्स में हमसे जुड़ें और आईटी प्रशिक्षण की दुनिया में
          बदलाव के लिए उत्प्रेरक बनें।
        </p>
      </div>
      <div>
        <section className="light">
          <div className="containeroutreach py-2">
            <div className="h1 text-center text-dark" id="pageHeaderTitle">
              Franchise Center
              <p className="ourotreachpara text-center mt-3">
                {" "}
                At Miracle IT Skills, we've been at the forefront of IT
                education for years, transforming individuals into tech-savvy
                professionals. As an Franchise Center, you'll play a pivotal role
                in extending this transformation to your local community and
                beyond
              </p>
            </div>
            <div className="container12345 mb-3" style={{ margin: "auto" }}>
              <div className="row">
                <div className="col-md mt-2">
                  <div className="card thiscardfrinches -4 h-100">
                    <img
                      src={require("../../assest/frincise1.png")}
                      className="frinchescard"
                      alt="miracle it skills"
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        Zero extra establishment expenses
                      </h5>
                      <p className="card-text text-center">
                        With a focus on affordability and accessibility, we
                        ensure that your investment is solely directed towards
                        quality education.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div className="card thiscardfrinches  h-100">
                    <img
                      src={require("../../assest/frincise2.png")}
                      className="frinchescard"
                      alt="miracle it skills"
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        Zeal To Be Your Own boss
                      </h5>
                      <p className="card-text text-center">
                        For more than 12 years, we have been professionally
                        instructing and preparing professionals, college
                        graduates, and students for the workforce.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2 ">
                  <div className="card thiscardfrinches h-100">
                    <img
                      src={require("../../assest/frincise3.png")}
                      className="frinchescard"
                      alt="miracle it skills"
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        Minimum Carpet Area of 1500 Sqft
                      </h5>
                      <p className="card-text text-center">
                        A fully furnished IT training facility with a minimum of
                        three computer labs housing thirty computers and a
                        1500–2000 square foot carpet area
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <article className="postcard light blue">
              <NavLink className="postcard__img_link" to="/">
                <img
                  className="postcard__img"
                  src={require("../../assest/Franchise.webp")}
                  alt=" Miracle IT Career Title"
                />
              </NavLink>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title blue">
                  <NavLink to="/">What is Franchise Program?</NavLink>
                </h1>
                <div className="postcard__subtitle small">
                  <time dateTime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2" />
                    Become an Franchise partner and explore the benefits
                  </time>
                </div>
                <div className="postcard__bar" />
                <div className="postcard__preview-txt">
                  The Franchise Program is a comprehensive support initiative
                  that encompasses a range of services and resources aimed at
                  helping franchisees thrive. It's our way of ensuring that you
                  have the tools and guidance you need to achieve your business
                  goals. Here's what it includes:
                  <br />
                  <br />
                  {/* <ul>
                    <li>Marketing Support </li>
                    <li> Training and Education</li>
                    <li> Operational Guidance</li>
                    <li> Technical Assistance</li>
                    <li> Networking Opportunities</li>
                    <li> Expansion Support</li>
                  </ul> */}
                  <div className="card">
                    <h5 className="card-header"> Features</h5>
                    <div className="card-body">
                      <h5 className="card-title mycardstitels">
                        <li>Marketing Support </li>
                        <li> Training and Education</li>
                        <li> Operational Guidance</li>
                        <li> Technical Assistance</li>
                        <li> Networking Opportunities</li>
                        <li> Expansion Support</li>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="postcard light red">
              <NavLink className="postcard__img_link" to="/">
                <img
                  className="postcard__img"
                  src={require("../../assest/Center.webp")}
                  alt=" Miracle IT Career Title"
                />
              </NavLink>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title red">
                  <NavLink to="/">Benefits for the Partner </NavLink>
                </h1>
                <div className="postcard__subtitle small">
                  <time dateTime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2" />
                    What will you get out of it?
                  </time>
                </div>
                <div className="postcard__bar" />
                <div className="postcard__preview-txt">
                  Partnering with Miracle IT Skills through our Franchise Program
                  is a strategic move that can accelerate your business's growth
                  and provide you with the support and resources you need to
                  thrive. Together, we can achieve remarkable success in the
                  ever-evolving world of IT and technology.
                  <br />
                  <br />
                  Ready to explore the possibilities of partnership? Contact us
                  today to learn more about how our Franchise Program can benefit
                  you and your business.
                </div>
              </div>
            </article>
            <article className="postcard light green">
              <NavLink className="postcard__img_link" to="/">
                <img
                  className="postcard__img"
                  src={require("../../assest/benefits.webp")}
                  alt=" Miracle IT Career Title"
                />
              </NavLink>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title green">
                  <NavLink to="/">Centre Franchise</NavLink>
                </h1>
                <div className="postcard__subtitle small">
                  <time dateTime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2" />
                    Grow step-by-step
                  </time>
                </div>
                <div className="postcard__bar" />
                <div className="postcard__preview-txt">
                  Expanding your business through a franchise model can be a
                  step-by-step process that allows you to grow steadily and
                  successfully. Here's a roadmap for establishing a center
                  franchise.
                  <ul>
                    <h3 className="masterfrin mt-2">
                      Who can become a CENTRE FRANCHISEE?{" "}
                    </h3>
                    <li> Having a strong network IT aspirants and learners</li>
                    <li>
                      An IT traning institiute with 1500-2000 Sqft carpet area
                      and minimum 3 computer labs with 30 computers
                    </li>
                  </ul>
                  <ul>
                    <h3 className="masterfrin">Benefits </h3>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-sm-4 mb-3 mb-sm-0 myoutreachcollum">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title mycardstitels">
                          <ul>
                          <li>
                              Guaranteed earning potential of minimum INR 40
                              thousand per month
                            </li>
                            <li>
                              Quality assurance & 100% lead generation through
                              digital marketing
                            </li>
                            
                          </ul>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 myoutreachcollum">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title mycardstitels">
                          <ul>
                            <li>
                              {" "}
                              In-house ERP, CRM, and research and development
                              team & regular seminar and grooming sessions
                            </li>
                            <li>
                              {" "}
                              Practical training sessions with fun activities &
                              faculty training
                            </li>
                          </ul>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 myoutreachcollum">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title mycardstitels">
                          <ul>
                            <li>
                              {" "}
                              Partnership Advantage with India’s top-ranked
                              training institute
                            </li>
                            <li>
                              {" "}
                              Best Training facilities in Accounts, Finance and
                              Taxation
                            </li>
                            <li>Zero extra expenses </li>
                          </ul>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="applynowbutton mt-2">
                  <button className="btntn" onClick={toggleEnquiryForm}>
                    {" "}
                    Apply Now
                  </button>
                </div>
              </div>
            </article>
            <article className="postcard light yellow">
              <NavLink className="postcard__img_link" to="/">
                <img
                  className="postcard__img"
                  src={require("../../assest/what is Franchise.webp")}
                  alt=" Miracle IT Career Title"
                />
              </NavLink>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title yellow">
                  <NavLink to="/">Master Franchise</NavLink>
                </h1>
                <div className="postcard__subtitle small">
                  <time dateTime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2" />
                    Think big, grow bigger.
                  </time>
                </div>
                <div className="postcard__bar" />
                <div className="postcard__preview-txt">
                  At Miracle IT Skills, we believe in the power of thinking big
                  and growing even bigger. Our Master Franchise opportunities
                  are your gateway to unlimited potential. Join our network of
                  successful entrepreneurs who have leveraged our proven system
                  to achieve remarkable growth. As a Master Franchisee, you'll
                  not only benefit from our global presence and industry
                  expertise but also gain the autonomy to build and expand your
                  own network of sub-franchisees. Take the leap and seize the
                  opportunity to unlock your financial success. Think big with
                  Miracle IT Skills, and together, we'll grow bigger than you
                  ever imagined.
                  <ul>
                    <h3 className="masterfrin mt-2">
                      {" "}
                      Who can become a MASTER FRANCHISEE?{" "}
                    </h3>
                    <li>
                      {" "}
                      Having a strong network of IT training institutes in your
                      division/district/surrounding Locality
                    </li>
                    <li>
                      {" "}
                      A Well set-up IT traning institiute with 1500-2000 Sqft
                      carpet area and minimum 3 computer labs with 30 computer
                    </li>
                    <li>Ready to invest 10-15 lacs rupees</li>
                    <li>Capacity to lead a network of 10-15 franchisees</li>
                  </ul>
                  <ul></ul>
                </div>
                <div className="card">
                  <h5 className="card-header"> Benefits</h5>
                  <div className="card-body">
                    <h5 className="card-title mycardstitels">
                      <li> Guaranteed return on your Investment.</li>
                      <li> Assured monthly income.</li>
                      <li>
                        {" "}
                        Guaranteed annual income of 10-15 lacs rupees per year.
                      </li>
                    </h5>
                  </div>
                </div>

                <div className="applynowbutton mt-2">
                  <button className="btntn" onClick={toggleEnquiryForm}>
                    {" "}
                    Apply Now
                  </button>
                </div>
              </div>
            </article>
            {showEnquiryForm && <Inquri />}
          </div>
        </section>
      </div>
    </>
  );
};

export default Franchise;
