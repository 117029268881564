import { useEffect } from "react";
import "./home.css";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import HomeCard from "../morecards/HomeCard";
import { NavLink } from "react-router-dom";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
const Home = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="section aboutme" data-anchor="aboutme">
        <div className="opaque-bg animated fadeInDown">
          <h1 className="fristheading">
            MIRACLE IT SKIILLS <br />
            सुनहरा भविष्य यहां है
          </h1>
          <p>
            <span id="holder" />
            <span className="blinking-cursor">
              {" "}
              Challenge yourself - It will lead to GROWTH
            </span>
          </p>
        </div>

        <GetAppSharpIcon className="bounce" />
      </div>
      {/* cards add  */}
      <div className="row cardpage">
        <div className="col-sm-7 colsm8">
          <p className="thisabout">about us</p>
          <h1 className="thisabouth1">
            Technology Advancement and Skilled Manpower are key to "NEW BHARAT"
          </h1>
          <p className="thisabouth3">
            Technology and skilled manpower plays a vital role in the country's
            growth and development. Today, India is among the nations with
            largest manpower across the globe. Yet there is a gap! gap between
            skills-in-demand and skillset of the current workforce, this gap can
            be eliminated with the right training. <br></br> <br></br> Miracle
            Group is working with the aim to provide the desired IT Skills to
            students willing to have a career in IT Industry.
          </p>
        </div>
        <div className="col-sm-4 colsm4">
          <div className="card2descrption">
            In addition to working directly with students, we also believe in
            empowering the teachers and trainers who in turn work with learners
            over the long term. We support those who help others as well.
            <br /> <br />
            Miracle Group is focused on creating India's largest IT educational
            network focused on national good.
          </div>
        </div>
      </div>
      <NavLink to="/adsd">
        <img
          src={require("../../assest/miracleschoolnew.jpg")}
          className="homebanner1"
          alt="miracleitskills"
        />
        <img
          src={require("../../assest/Miracle mobile view.jpg")}
          className="miracleitskillsmobile"
          alt="miracleitskills"
        />
      </NavLink>
      <div className="ourpatners mt-4">
        <h1 className="frinchesstext text-center mb-4">
          {" "}
          We Encourage Our Franchise Partners
        </h1>
        <p className="outreacthome text-center">
          At Miracle IT Skills we are committed to not only growing our brand
          but also supporting our franchisees in their journey towards success.
          Our Franchise Program is a vital component of this commitment, designed
          to empower you and your franchise business.
        </p>
        <NavLink to="/outreach">
          <img
            src={require("../../assest/Miracle - IT (7) (2).webp")}
            className="homebanner1"
            alt="miracleitskills"
          />

          <img
            src={require("../../assest/miracleitjoin1.webp")}
            className="miracleitskillsmobile"
            alt="miracleitskills"
          />
        </NavLink>
        <div className="ourpatenerstext"></div>
      </div>
      {/* <div className="silentfeatcher mt-3">
        <h2 className="silentfeatcher11 text-center"> Salient features of The Miracle Franchise Program </h2>
        <div class="row silentfeatchercontent ">
          <div class="col-sm-3 miraclefeature">
            Member of United States Distance Learning Association
          </div>
          <div class="col-sm-3 miraclefeature">ISO QMS Certified</div>
          <div class="col-sm-3 miraclefeature"> NASSCOM Membership</div>
          <div class="col-sm-3 miraclefeature"> IT/ITes NASSCOM Curriculum</div>
          <div class="col-sm-3 miraclefeature"> Offline Centres and Helpdesk</div>
          <div class="col-sm-3 miraclefeature"> Member of SKill India Employment Program</div>
          <div class="col-sm-3 miraclefeature"> 100% Job Assurance Training</div>
          
          <div class="col-sm-3 miraclefeature"> No Other Investment</div>
          <div class="col-sm-3 miraclefeature"> Member of MSME</div>
          <div class="col-sm-3 miraclefeature"> Government Projects</div>
          <div class="col-sm-3 miraclefeature"> Certificate Programs</div>
          <div class="col-sm-3 miraclefeature"> Corporate CSR</div>
          <div class="col-sm-3 miraclefeature"> Corporate Training </div>
          <div class="col-sm-3 miraclefeature"> Group Alumni Association </div>
          <div class="col-sm-3 miraclefeature"> Monthly Pay-outs (Guaranteed)</div>
          <div class="col-sm-3 miraclefeature"> Easy Earning</div>
          <div class="col-sm-3 miraclefeature">Business Transparency </div>
          <div class="col-sm-3 miraclefeature">Flexible Leaning Hours </div>
          <div class="col-sm-3 miraclefeature">Sophisticated Panel</div>
          <div class="col-sm-3 miraclefeature">200+ Company Association for Employment</div>
        </div>
      </div> */}
      <div className="tabs-box">
        <div className="acord">
          <label htmlFor="tb-one" className="bg-one" >
          <DoubleArrowIcon style={{marginRight:"5px"}}/>
            How is it beneficial for you - Salient features of The Miracle Franchise Program  <DoubleArrowIcon  className="arrow123"/>
          </label>
          <input type="checkbox" name="mytabs" id="tb-one" />
          <i className="fa fa-chevron-circle-down" />
          <div className="content">
            <span className="square" />
            <div class="row silentfeatchercontent ">
              <div class="col-sm-3 miraclefeature">
                Member of United States Distance Learning Association
              </div>
              <div class="col-sm-3 miraclefeature">ISO QMS Certified</div>
              <div class="col-sm-3 miraclefeature"> NASSCOM Membership</div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                IT/ITes NASSCOM Curriculum
              </div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                Offline Centres and Helpdesk
              </div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                Member of SKill India Employment Program
              </div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                100% Job Assurance Training
              </div>

              <div class="col-sm-3 miraclefeature"> No Other Investment</div>
              <div class="col-sm-3 miraclefeature"> Member of MSME</div>
              <div class="col-sm-3 miraclefeature"> Government Projects</div>
              <div class="col-sm-3 miraclefeature"> Certificate Programs</div>
              <div class="col-sm-3 miraclefeature"> Corporate CSR</div>
              <div class="col-sm-3 miraclefeature"> Corporate Training </div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                Group Alumni Association{" "}
              </div>
              <div class="col-sm-3 miraclefeature">
                {" "}
                Monthly Pay-outs (Guaranteed)
              </div>
              <div class="col-sm-3 miraclefeature"> Easy Earning</div>
              <div class="col-sm-3 miraclefeature">Business Transparency </div>
              <div class="col-sm-3 miraclefeature">Flexible Leaning Hours </div>
              <div class="col-sm-3 miraclefeature">Sophisticated Panel</div>
              <div class="col-sm-3 miraclefeature">
                200+ Company Association for Employment
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="ourdomans text-center mt-3">Our Domains</h1>

      <HomeCard />
      <img
        src={require("../../assest/Miracle - IT (2).webp")}
        className="homebanner1"
        alt="miracleitskills"
      />
    </>
  );
};

export default Home;
