import React from "react";
import "./homecard.css";
import { NavLink } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
const HomeCard = () => {
  return (
    <>
      <div className="row col-12 myfristhomecard">
        <div className="col-sm-3 mb-3 mb-sm-0 classcard1">
          <main>
            <div className="cardhome">
              <div className="image-container">
                <img
                  src={require("../../assest/1.webp")}
                  alt="Miracle IT Skills"
                  className="image"
                />
                <div className="overlay"></div>
                <div className="cardhome-content">
                  <h2 className="homecardh2">Miracle IT Career Academy</h2>
                  <p>
                    Miracle IT Career Academy is working in the field of IT
                    trainings and placements. It helps technical & non-technical
                    students to start their career in IT industry.
                  </p>
                  <NavLink to="/about" className="button">
                    Find out more
                    <span className="material-symbols-outlined">
                      <EastIcon />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div className="col-sm-3 classcard1">
          <main>
            <div className="cardhome">
              <div className="image-container">
                <img
                  src={require("../../assest/2.webp")}
                  alt="Miracle IT Skills"
                  className="image"
                />
                <div className="overlay"></div>
                <div className="cardhome-content">
                  <h2 className="homecardh2">Miracle Infoserv</h2>
                  <p>
                    Software development, manpower planning, Govt. Projects and
                    more. With the domain expertise Miracle Infoserv is
                    dedicated to the client side services.
                  </p>
                  <NavLink to="/about" className="button">
                    Find out more
                    <span className="material-symbols-outlined">
                      <EastIcon />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="col-sm-3 classcard1">
          <main>
            <div className="cardhome">
              <div className="image-container">
                <img
                  src={require("../../assest/3.webp")}
                  alt="Miracle IT Skills"
                  className="image"
                />
                <div className="overlay"></div>
                <div className="cardhome-content">
                  <h2 className="homecardh2">Miracle Infosoft</h2>
                  <p>
                    Miracle Infosoft is working in the domain of web
                    development, app development and other technology solutions.
                    Join our team - apply for internship.
                  </p>
                  <NavLink to="/about" className="button">
                    Find out more
                    <span className="material-symbols-outlined">
                      <EastIcon />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="col-sm-3 classcard1">
          <main>
            <div className="cardhome">
              <div className="image-container">
                <img
                  src={require("../../assest/4.webp")}
                  alt="Miracle IT Skills"
                  className="image"
                />
                <div className="overlay"></div>
                <div className="cardhome-content">
                  <h2 className="homecardh2">Miracle Foundation</h2>
                  <p>
                    Miracle IT Foundation supports programs in the areas of IT
                    education, rural development, IT skill development, arts and
                    culture, and destitute care.
                  </p>
                  <NavLink to="/about" className="button">
                    Find out more
                    <span className="material-symbols-outlined">
                      <EastIcon />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default HomeCard;
