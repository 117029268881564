import React, { useState, useEffect } from "react";
import "./cource.css";

const Cource1 = () => {
  const initialText2 = `
  In senior curriculum, students will develop the skills to program in python
language with understanding the concepts of basic programming in open source environment.
This course will enable the students interested in computer science to understand the
functionality and solve the exercises as per the NEP 2020 computer education guidelines for class 11th and 12th.
This will set the foundation for the future software engineers and web developers, with providing the
basic programming skills to every other student enrolling for this curriculum.
  `;

  const [showImage1, setShowImage1] = useState(false);
  const [showFullText1, setShowFullText1] = useState(false);

  const toggleText1 = () => {
    setShowFullText1(!showFullText1);
  };

  const toggleImage1 = () => {
    setShowImage1(!showImage1);
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <h1 className="ourcource text-center" style={{ marginTop: "30px" }}>
          Senior Program
        </h1>
        <h3 className="ourcource text-center" style={{ marginTop: "30px" }}>
          Aligne to "The National Education Policy (NEP) 2020"
        </h3>
        <p className="aboutnep" style={{ padding: "20px" }}>
          The National Education Policy (NEP) 2020 is an educational policy in
          India that was approved by the Union Cabinet on July 29, 2020. It
          marks a significant reform in the Indian education system and aims to
          provide a comprehensive framework for the transformation of the
          country's educational landscape
          <ul>
            <li>
              <strong> Holistic Education: </strong>The NEP emphasizes a shift
              from a rigid and content-based curriculum to a more flexible and
              holistic approach. It aims to promote critical thinking,
              creativity, and a multidisciplinary approach to education.
            </li>
            <li>
              <strong> Institute Education:</strong> The NEP promotes the
              5+3+3+4 curricular and pedagogical structure, which includes five
              years of foundational education, three years of preparatory
              education, three years of middle Institute, and four years of
              secondary Institute. This replaces the traditional 10+2 structure.
            </li>
            <li>
              <strong> Higher Education: </strong> The policy suggests the
              establishment of a National Research Foundation to promote
              research and innovation in higher education. It also aims to make
              higher education more flexible and interdisciplinary.
            </li>
            <li>
              <strong>Digital Education: </strong> The NEP promotes the use of
              technology in education and the development of e-content. It aims
              to create a National Educational Technology Forum (NETF) to
              facilitate the integration of technology in education.
            </li>
          </ul>
        </p>
        <div className="containerpp">
          <div className="row">
            <div className="col-12">
              <article className="blog-card">
                <div className="blog-card__background">
                  <div className="card__background--wrapper">
                    <div
                      className="card__background--main"
                      style={{
                        backgroundImage: `url(${require("../../assest/cource2.jpg")})`,
                      }}
                    >
                      <div className="card__background--layer" />
                    </div>
                  </div>
                </div>
                <div className="blog-card__head">
                  <span className="date__box">
                    <span className="date__day">Senior</span>
                    <span className="date__month">Tech-Champ</span>
                  </span>
                </div>
                <div className="blog-card__info">
                  <h5>Tech-Champ Senior</h5>
                  <p>
                    <div className="icon-link mr-3">
                      <i className="fa fa-pencil-square-o" /> Tech-Champ Senior
                    </div>
                    <div className="icon-link">
                      <i className="fa fa-comments-o" /> - Overview
                    </div>
                  </p>
                  <p>
                    {showFullText1
                      ? initialText2
                      : initialText2.split(" ").slice(0, 30).join(" ")}
                  </p>
                  {showImage1 && (
                    <img
                      src={require("../../assest/Miracle - IT (1) (1).webp")}
                      className="toggelimg"
                      alt="Miracleitskills"
                    />
                  )}

                  <button
                    className="btnttp btn--with-icon"
                    onClick={() => {
                      toggleText1();
                      toggleImage1();
                    }}
                  >
                    <i
                      className={`btn-icon fa ${
                        showFullText1
                          ? "fa-long-arrow-up"
                          : "fa-long-arrow-down"
                      }`}
                    />
                    {showFullText1 ? "READ LESS" : "READ MORE"}
                  </button>
                </div>
              </article>
            </div>
          </div>
        </div>

        <h3 className="ourcource text-center" style={{ marginTop: "30px" }}>
          WHY YOUR INSTITUTE SHOULD JOIN THIS PROGRAM
        </h3>
        <p className="aboutnep" style={{ padding: "20px" }}>
          <ul>
            <li>
              Your Institute will get a chance to take part a national level
              talent hunt program for your students.
            </li>
            <li>
              Your students will get the training from the industry experts This
              course is prepared keeping in mind the CBSE course curriculum for
              these age group of students aligned with the course guideline of
              New Education Policy (NEP 2020)
            </li>
            <li>
              Students joining the program will have to appear in certain tests
              of their learnings and will get certified by the International
              bodies by EC-Council and others at the same time from IT/ITes
              Sector Skill Council (an agency leading the learning and
              development in the particular sector) NASSCOM or its associates.
            </li>
            <li>
              A state level program will be organized in which we will be
              felicitating the student achieving the position at state level
              also the school officials will be recognized for the same.
            </li>
            <li>The entire program will have adequate media coverage.</li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default Cource1;
