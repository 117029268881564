const blogData = [
    {
      id: 1,
      image: require("../../assest/blog1.png"),
      author: "Mr. Amit Dubey",
      date: "July 15, 2015",
      tags: ["Learn", "Code", "JavaScript"],
      title:
        "Why Capturing Email Is So Important for Your Website & How to Do It",
      content:
        "Capturing email addresses on your website is vital for several reasons. Email remains a powerful and direct channel for communication with your audience, enabling you to foster relationships and drive conversions. It's an indispensable tool for businesses and organizations of all sizes, offering a range of benefits.Email allows you to communicate directly with your audience, making it personal and engaging. You can send tailored messages, updates, promotions, and valuable content right to their inboxes. This direct line of communication builds trust and brand loyalty over time. Additionally, email marketing is a valuable tool for customer retention. It keeps existing customers informed about your products or services, reducing churn rates and increasing customer lifetime value.",
      readMoreLink: "/singleblog",
    },
    {
      id: 2,
      image: require("../../assest/blog2.png"),
      author: "Mr. Amit Dubey",
      date: "July 15, 2015",
      tags: ["Learn", "Code", "JavaScript"],
      title:
        "How Many Websites Use HTML? 10 Astonishing Statistics That Shape Web Development",
      content:
        "HTML is the Fundamental Language: HTML (Hypertext Markup Language) serves as the foundation of the World Wide Web, and nearly all websites use it to structure content.Universal Adoption: HTML is utilized in web development across a wide spectrum of websites, from personal blogs to large e-commerce platforms and corporate sites.Open Web Standard: HTML is an open standard maintained by the W3C, ensuring its universality and consistency in web development. Responsive Design: HTML5, the latest version, introduced features supporting responsive web design, enabling websites to adapt to different devices and screen sizes.",
      readMoreLink: "/",
    },
    {
      id: 3,
      image: require("../../assest/blo3.png"),
      author: "Mr. Abhishek Dhok",
      date: "July 15, 2015",
      tags: ["Learn", "Code", "JavaScript"],
      title: "Crucial Cloudflare Statistics You Should Know in 2023",
      content:
        "Global Network Reach: In 2021, Cloudflare operated a vast network of data centers across the world, spanning over 200 cities in more than 100 countries. This network continues to expand, improving the speed and reliability of websites and applications Web Traffic Handling: Cloudflare serves a significant portion of global web traffic. As of 2021, they claimed to handle around 26 million HTTP requests per second on average. DDoS Mitigation: Cloudflare provides robust DDoS (Distributed Denial of Service) protection. They reported blocking attacks exceeding 17.2 Tbps (terabits per second) in 2021, making it one of the largest DDoS mitigation networks.",
      readMoreLink: "/",
    },
  ];
  export default blogData;