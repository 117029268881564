
import "./blog.css";
import blogData from "./Blogdata"; // Adjust the path to match your project structure
import { NavLink, useParams } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect } from "react";
function SingleBlog(props) {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // Get the id from the URL
  const { id } = useParams();
  // Find the blog post with the matching ID
  const blogPost = blogData.find((blog) => blog.id.toString() === id);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <div>
      <div className="singlemiracleback">
        <h1 className="singlemiracle text-center">{blogPost.title}</h1>
        <p className="author text-center pb-3">
          {" "}
          Author : {blogPost.author} / Date: {blogPost.date} / Content :{" "}
          {blogPost.tags}{" "}
        </p>
      </div>

      <div className="row singleblockrow">
        <div className="col-sm-8 mb-3 mb-sm-0 m-auto">
          <p className="psingleblog mt-2 text-center">
            <NavLink to="/">Home</NavLink> <ArrowForwardIcon />{" "}
            <NavLink to="/blog"> Blog</NavLink> <ArrowForwardIcon />
            {blogPost.title}
          </p>
          <div className="singleblogimg">
            <img
              src={blogPost.image}
              className="singleblogimg"
              alt="Miracle IT Skills"
            ></img>

            <div className="containtsingleblog mt-5">{blogPost.content}</div>
          </div>
          <h1 className="singlemiracle11 mt-4 text-center"> Leave a Reply</h1>
          <form className="form11">
            <input
              name="name"
              type="text"
              className="feedback-input"
              placeholder="Name"
            />
            <input
              name="email"
              type="text"
              className="feedback-input"
              placeholder="Email"
            />
            <textarea
              name="text"
              className="feedback-input comments"
              placeholder="Comment"
              defaultValue={""}
            />
            <input type="submit" className="submitbuttom" defaultValue="SUBMIT" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default SingleBlog;
