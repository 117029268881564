import { useEffect } from "react";

import "./cource.css";
import { NavLink } from "react-router-dom";
const Adsd = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <h1 className="ourcource text-center" style={{ marginTop: "30px" }}>
        {" "}
        Advance Diploma in Software Development
      </h1>
      <div className="containerpp mt-5">
        <div className="row">
          <div className="col-12">
            <article className="blog-card">
              <div className="blog-card__background" style={{height:"50%"}}>
                <div className="card__background--wrapper">
                  <div
                    className="card__background--main"
                    style={{
                      backgroundImage: `url(${require("../../assest/cource3.jpg")})`,
                    }}
                  >
                    <div className="card__background--layer" />
                  </div>
                </div>
              </div>
              <div className="blog-card__head">
                <span className="date__box">
                  <span className="date__day">Advance</span>
                  <span className="date__month">Diploma</span>
                </span>
              </div>
              <div className="blog-card__info">
                <h5>Advance Diploma in Software Development</h5>
                <p>
                  <NavLink to="/" className="icon-link mr-3">
                    <i className="fa fa-pencil-square-o" />
                    Career Program
                  </NavLink>
                  <NavLink to="/" className="icon-link">
                    <i className="fa fa-comments-o" /> - Overview
                  </NavLink>
                </p>
                <p>
                  An advanced diploma in software development is an educational
                  program that offers in-depth training and knowledge in various
                  aspects of software development. This diploma goes beyond the
                  basics of programming and covers advanced topics and
                  techniques, making it suitable for individuals who want to
                  enhance their skills and knowledge in software development.
                  <br></br>
                  <br></br>
                  Here are some key features and components commonly associated
                  with an advanced diploma in software development:
                  <br></br>
                  <br></br>
                  <ul>
                    <li>
                      {" "}
                      <strong>Advanced Programming</strong>: This program
                      include advanced programming languages and concepts, such
                      as object-oriented programming, data structures,
                      algorithms, and design patterns.
                    </li>
                    <br></br>

                    <li>
                      {" "}
                      <strong>Web Development</strong>: Our advanced diploma
                      cover web development technologies like HTML, CSS,
                      JavaScript, and server-side scripting languages (e.g.,
                      PHP, Python, etc.).
                    </li>
                    <br></br>

                    <li>
                      {" "}
                      <strong>Database Management</strong>: Knowledge of
                      databases, SQL, and database management systems is
                      typically a part of the curriculum to help students
                      understand how to store and retrieve data efficiently.
                    </li>
                    <br></br>
                    <li>
                      {" "}
                      <strong>Skill Development</strong>: In skill enhancement
                      module you will learn get the guidance on project
                      creation, interview preparations, resume building and Mock
                      interviews.
                    </li>
                  </ul>
                  The specific content and duration of advanced diploma programs
                  is designed in sync with the IT industry. This programs are
                  created to prepare students for careers in software
                  development, either as software engineers, web developers,
                  mobile app developers, or other related roles.
                </p>
              </div>
              <img
                src={require("../../assest/ADSD - Miracle IT Skills.webp")}
                className="toggelimg"
                alt="Miracleitskills"
              />
            </article>
          </div>
        </div>
        <div className="careergroth">
          <img
            src={require("../../assest/adsd4.png")}
            className="toggelimgcareer "
            alt="Miracleitskills"
          />
        </div>
      </div>

      <div className="careergroth mt-2">
          <img
            src={require("../../assest/miraclegraph.jpg")}
            className="toggelimgcareer" style={{width:"100%"}}
            alt="Miracleitskills"
          />
        </div>
      
      
    </>
  );
};

export default Adsd;
