import "./App.css";
import Contact from "./component/contact/Contact";
import Footerjs from "./component/footer/Footerjs";
import Home from "./component/home/Home";
import Navbar from "./component/navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import AboutPage from "./component/about/AboutPage";
import Cyber from "./component/project/Cyber";
import Kartavya from "./component/project/Kartavya";
import PlacementSupport from "./component/placement/PlacementSupport";
import OutReach from "./component/ouroutreach/OutReach";
import Cource from "./component/cource/Cource";
import Blog from "./component/blog/Blog";
import SingleBlog from "./component/blog/SingleBlog";
import Adsd from "./component/cource/Adsd";
import Error from "./component/404page/Error";
import GoToTop from "./component/GotoTop";
import Cource1 from "./component/cource/Cource1";
import School from "./component/school/School";
function App() {
  return (
    <>
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage/>} />
      <Route path="/cyber" element={<Cyber/>} />
      <Route path="/kartavya" element={<Kartavya/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/outreach" element={<OutReach/>} />
      <Route path="/cource" element={<Cource/>} />
      <Route path="/cource1" element={<Cource1/>} />
      <Route path="/adsd" element={<Adsd/>} />
      <Route path="/blog" element= {<Blog/>}/>
      <Route path="/blog/:id" element={<SingleBlog/>} />
      <Route path="/placement" element={<PlacementSupport/>} />
      <Route path="/school" element={<School/>} />
      
      <Route path ="*" element={<Error/>}/>
      </Routes>
      <Footerjs/>
      <GoToTop/>
    </>
  );
}

export default App;
