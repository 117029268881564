import { useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import { LiaChalkboardTeacherSolid } from "react-icons/lia";
const Cyber = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        id="carouselExampleInterval"
        data-bs-ride="carousel"
        
      >
        <div className="carousel-inner">
          {/* <Slider {...settings}> */}
          <div className="carousel-item active">
            <img
              src={require("../../assest/MiracleIT.webp")}
              className="d-block w-100 mybanner"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div className="card-group">
        <div
          className="card cardcyber"
          style={{ backgroundColor: "#275f9a", alignItems: "center" }}
        >
          <div className="cardbody">
            <h5 className="servicecard">What is Cyber-Secruity?</h5>
            <p className="cardtext">
              Understanding about cyber crimes and ways to secure yourself and your family from
              any such cyber attack.
            </p>
          </div>
        </div>
        <div
          className="card"
          style={{ backgroundColor: "#0d1128", alignItems: "center" }}
        >
      
          <div className="cardbody">
            <h5 className="servicecard">Why cyber awareness is important?</h5>
            <p className="cardtext">
              In the digital world cyber security is one of the most important
              thing, better you learn about it now.
            </p>
          </div>
        </div>
        <div
          className="card"
          style={{ backgroundColor: "#275f9a", alignItems: "center" }}
        >
          <div className="cardbody">
            <h5 className="servicecard">What security challenges you face?</h5>
            <p className="cardtext">
              Compromised wifi, USB hacking, Email & camare hacking and phishing
              are some common attacks, beware!
            </p>
          </div>
        </div>

        <div
          className="card"
          style={{ backgroundColor: "#0d1128", alignItems: "center" }}
        >
          <div className="cardbody">
            <h5 className="servicecard">Security in everyday life</h5>
            <p className="cardtext">
              Secure payment and banking apps, keeping the things PIN/Password
              protected is one way to be safe.
            </p>
          </div>
        </div>
      </div>
      <div
        className="row itskills"
        style={{
          marginTop: "12px",
          marginLeft: "10px",
          marginRight: "20px",
          padding: "2%",
        }}
      >
        <div
          className="col-sm-2 mb-3 mb-sm-0 colsm66"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
           
          }}
        >
          <img
            src={require("../../assest/Miraclecyber.webp")}
            style={{ width: "100%" }}
            alt="about1"
          />
        </div>
        <div className="col-sm-10 colsm66">
          <h2 className="hh1">
            Cyber Satarkata - A Miracle Infoserv Initiative
          </h2>
        

          <p className="pp1">
            <br />
            Cyber attack is increasingly targeted at individuals due to social
            engineering and logical engineering. The new normal of remote
            working has increased the focus on cyber security in terms of data
            protection. The COVID-19 pandemic has hampered companies by
            requiring them to confront their digital preparedness in tackling
            cyber threats. <br></br><br></br>Before the pandemic, employees typically worked from
            offices, where local area networks and desktops were adequately
            secured. Post-pandemic, support staff personnel and those needing
            direct system/hardware access are working from offices, while the
            rest of the workforce operates from home and connects to more
            vulnerable networks. <br></br><br></br>
            Organizations offer secure VPN access, but
            employees often connect via shared wireless networks, mobile
            hotspots, or broadband networks. This data traffic flow is
            uncontrolled and can compromise the enterprise network.
            Additionally, there is a risk of IP theft and leakages, especially
            for work-from-home users in research and development organizations.
            <br />
            <br /> For details related to{" "}
            <strong>"Secured Cyber User Program"</strong> please contact{" "}
            <strong>+91 788 000 3128</strong> or visit our branch near you.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-sm-4 cybercard">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title cybertitel">
                Privacy and data protection
              </h5>
              <p className="card-text cybertext">
                • Important of Data Privacy <br />• Privacy and the Data
                Protection Rules
                <br /> • Privacy and the Data Lifecycle <br />• Beneﬁts of ISO
                27001
                <br /> • Key highlights of Personal Data Protection Bill 2018{" "}
                <br />• Privacy and the Data Protection Rules
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 cybercard">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title cybertitel">Security at workplace</h5>
              <p className="card-text cybertext">
                • Phishing
                <br />
                • Electronic Communications
                <br />
                • Password Management
                <br />
                • Use of the Internet
                <br />
                • Personally Identiﬁable Information & Data Handling
                <br />
                • Ways to Prevent Ransomware
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 cybercard">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title cybertitel">
                Cybersecurity in daily life
              </h5>
              <p className="card-text cybertext">
                • Impact of unsafe remote working.
                <br />
                • The danger of working remotely.
                <br />
                • The best practices while working remotely
                <br />
                • Strategies to Avoid Fake Wi-Fi Attacks.
                <br />
                • Securing your mobile devices and application without falling
                into any trap set up by cybercriminals.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cyber;
