import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
const EnquiryForm = () => {
  const [showModal, setShowModal] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [emailid, setEmailid] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [occupation, setOccupation] = useState("");
  const [comment, setComment] = useState("");
  function saveData() {
    // Check if any of the form fields are empty
    if (
      !name || !mobileNumber || !emailid || !city || !state || !occupation || !comment
    ) {
      toast.error("Please fill in the details");
      return;
    }

    let data = { mobileNumber, name, emailid, state, city, occupation, comment }; // Include all form fields

    fetch(`http://localhost:3003/api/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((result) => {
        toast.success("Your from  has been submitted successfully");
      })
      .catch((error) => {
        toast.error("Try Again");
      });
      console.log(data)
  }

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Process the form data here or send it to a server

    // Clear the form fields after submission (if needed)
    setMobileNumber("");
    setName("");
    setEmailid("");
    setCity(" ");
    setState("");
    setOccupation("");
    setComment("");

    // Close the form after submission (if needed)
    setShowModal(false);
  };

  return (
    <div>
      <ToastContainer
        className="custom-toast-container"
        style={{ zIndex: "1000" }}
      />

      <Modal show={showModal} onHide={handleToggle}>
        {/* closeButton */}
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              padding: "20px",
              fontSize: "17px",
              fontWeight: "800",
              color: "white",
              textAlign: "center",
            }}
          >
            <h1 className="inquryh1"> Get In Touch. </h1>
            <p className="inqurip">
              Enter your details to start the <strong> Application </strong>
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mymodal">
          <div
            className=" col-sm-12 enquricol"
            style={{ display: "flex", margin: "auto" }}
          >
            {/* Image */}

            <div style={{ flex: "1", padding: "10px" }}>
              <form onSubmit={handleFormSubmit}>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "orangered",
                    fontSize: "19px",
                  }}
                >
                  <input
                    type="number"
                    maxlength="10"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                    placeholder="Enter Your Mobile"
                    className="inquriinput"
                  />
                </label>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "orangered",
                    fontSize: "19px",
                  }}
                >
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Enter Your Name"
                    className="inquriinput"
                  />
                </label>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "orangered",
                    fontSize: "19px",
                  }}
                >
                  <input
                    type="email"
                    value={emailid}
                    onChange={(e) => setEmailid(e.target.value)}
                    required
                    placeholder="Enter Your Email"
                    className="inquriinput"
                  />
                </label>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "orangered",
                    fontSize: "19px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "orangered",
                      fontSize: "19px",
                    }}
                  >
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                      placeholder="Enter Your State"
                      className="inquriinput"
                    />
                  </label>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "orangered",
                      fontSize: "19px",
                    }}
                  >
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                      placeholder="Enter Your City"
                      className="inquriinput"
                    />
                  </label>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "orangered",
                      fontSize: "19px",
                    }}
                  >
                    <input
                      type="text"
                      value={occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                      required
                      placeholder="Enter Your Occuption"
                      className="inquriinput"
                    />
                  </label>

                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                    placeholder="Enter Your Massage"
                    style={{ borderRadius: "4px", height: "10vh" }}
                  ></textarea>
                </label>
                <div className="btttn text-center" style={{ margin: "10px" }}>
                  <Button
                    type="submit"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid blue",
                      backgroundColor: "AppWorkspace",
                    }}
                    onClick={saveData}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EnquiryForm;
